(function(global, factory) {
    "use strict";
    if ("function" === typeof define && define.amd) {
        // If AMD is supported and the global object (window) in case any JS module is expecting
        // the AjaxHelper to exists on the window object.
        define('ajaxHelper',[],
            function() {
                return (global.AjaxHelper = factory(global));
            });
    } else if ("object" === typeof exports) {
        // If supports for Common JS is available export the module
        // and pass is value to the global (window) object in case non Common JS code 
        // is expected to work with this AjaxHelper.
        module.exports = (global.AjaxHelper = factory(global));
    } else {
        // If not support for AMD or Common JS is available register the module in the global scope.
        global.AjaxHelper = factory(global);
    }
})("undefined" !== typeof window ? window : {}, function(root) {

        var requestsQueue = [];
        var partnerElement = document.getElementsByClassName("brand-logo");
        var siteRoot = document.getElementById("nhs_siteRoot");
        let browserAcceptTypes = null;
        var partnerUrl;
        if (partnerElement && partnerElement.length > 0) {
            partnerUrl = partnerElement[0].href;
            partnerUrl = partnerUrl.replace("http:", window.location.protocol);
        } else {
            partnerUrl = "";
        }

        if (siteRoot) {
            siteRoot = siteRoot.value;
        } else {
            siteRoot = "";
        }

        var executeCallbacks = function(req, options, succeded, data) {
            if (typeof options === "undefined" || options === null) {
                options = {};
            }

            // Always
            if (typeof options.always === "function") {
                options.always(data);
            }
            // Success
            if (succeded && typeof options.success === "function") {
                options.success(data);
            } else if (typeof options.failed === "function") { // Failed
                options.failed(data);
            }

            var queueIndex = requestsQueue.indexOf(req);
            if (queueIndex > -1) {
                requestsQueue.splice(queueIndex, 1);
            }

        };
        var getRequest = function(url, contentType, parametersToSend, optionalCallbacks) {
            var xhr = new XMLHttpRequest();
            var req = {
                url: url,
                type: "GET",
                xhr: xhr
            };
            requestsQueue.push(req);

            xhr.open("GET", url, true);
            xhr.onreadystatechange = function() {
                if (xhr.readyState !== 4) {
                    return;
                }

                var succededStatus = xhr.status === 200;
                executeCallbacks(req, optionalCallbacks, succededStatus, xhr.responseText);
            };
            xhr.setRequestHeader("Content-type", contentType);
            xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
            xhr.setRequestHeader("Accept", getAcceptTypes());


            try {
                xhr.send(parametersToSend);
            } catch (err) {
                executeCallbacks(req, optionalCallbacks, false, err);
            }


        };

        var postRequest = function(url, options) {
            var xhr = new XMLHttpRequest();
            if (options.abort) {
                for (var i = 0; i < requestsQueue.length; i += 1) {
                    if (requestsQueue[i].url === url) {
                        requestsQueue[i].xhr.abort();
                        requestsQueue.splice(i, 1);
                    }
                }
            }

            var req = {
                url: url,
                type: "POST",
                xhr: xhr
            };

            requestsQueue.push(req);

            if (typeof options === "object") {

                xhr.open("POST", url, options.async ? options.async : true);
                xhr.setRequestHeader("Accept", getAcceptTypes());

                if (options.success || options.always || options.failed) {
                    xhr.onreadystatechange = function() {
                        if (xhr.readyState !== 4) {
                            return;
                        }

                        var succededStatus = xhr.status === 200;
                        executeCallbacks(req, options, succededStatus, xhr.responseText);
                    };
                }
                var formatJson = true;
                if (options.requestHeaders && options.requestHeaders.length > 0) {
                    for (var i = 0; i < options.requestHeaders.length; i += 1) {
                        xhr.setRequestHeader(options.requestHeaders[i].name, options.requestHeaders[i].value);
                    }
                }

                if (options.data) {
                  var data = null;
                  
                  if (options.noJsonStringify) {
                    data = options.data;
                  } else {
                    data = JSON.stringify(options.data);
                  }
                  

                    try {
                        xhr.send(data);
                    } catch (err) {
                        executeCallbacks(req, options, false, err);
                    }

                } else {
                    try {
                        xhr.send();
                    } catch (err) {
                        executeCallbacks(req, options, false, err);
                    }
                }
            }

        };


        var request = function(options) {
            // Basic validation
            if (!options ||
                typeof options !== "object" ||
                !options.url ||
                typeof options.url !== "string" ||
                !options.type ||
                typeof options.type !== "string") {
                throw new Error("Invalid request options");
            }
            var xhr = new XMLHttpRequest();
            if (options.abort) {
                var requestToAbort = requestsQueue.find(function(r) {
                    return r.url === options.url;
                });

                if (requestToAbort) {
                    requestToAbort.xhr.abort();
                    requestsQueue.splice(requestToAbort.indexOf(requestToAbort, 1));
                }
            }

            var requestForQueue = {
                url: options.url,
                type: options.type,
                xhr: xhr
            };

            requestsQueue.push(requestForQueue);

            xhr.open(options.type.toUpperCase(), options.url, true);


            if (typeof options.success === "function" ||
                typeof options.failed === "function" ||
                typeof options.always === "function") {
                xhr.onreadystatechange = function() {
                    if (xhr.readyState !== 4) return;

                    requestsQueue.splice(requestsQueue.indexOf(requestForQueue), 1);

                    var response;

                    try {
                        response = JSON.parse(xhr.responseText);
                    } catch (e) {
                        response = xhr.responseText;
                    }

                    if (xhr.status === 200 && typeof options.success === "function") {
                        options.success(response);
                    } else if (typeof options.failed === "function") {
                        options.failed(response);
                    }
                    if (typeof options.always === "function") {
                        options.always(response);
                    }
                };
            }

            if (options.type.toUpperCase() !== "GET" && options.data && typeof options.data === "object") {
                xhr.setRequestHeader("Content-type", "application/json");
            }

            xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
            xhr.setRequestHeader("Accept", getAcceptTypes());

            xhr.send(options.data && typeof options.data === "object"
                ? JSON.stringify(options.data)
                : options.data
                ? options.data
                : void (0));

            return xhr;
        };

        HTMLFieldSetElement.prototype.serialize = function() {
            var notSerializableTypes = ["file", "reset", "submit", "button"];
            var field, l, s = [];

            if (typeof this === "object" && this.nodeName === "FIELDSET") {
                var len = this.elements.length;

                for (var i = 0; i < len; i += 1) {
                    field = this.elements[i];

                    if (!field.name || field.disabled || notSerializableTypes.includes(field.type)) {
                        continue;
                    }
                    if (field.type === "select-multiple") {
                        l = this.elements[i].options.length;

                        for (var j = 0; j < l; j += 1) {
                            if (field.options[j].selected) {
                                s[s.length] =
                                    encodeURIComponent(field.name) + "=" + encodeURIComponent(field.options[j].value);
                            }
                        }
                    } else if ((field.type !== "checkbox" && field.type !== "radio") || field.checked) {
                        s[s.length] = encodeURIComponent(field.name) +
                            "=" +
                            encodeURIComponent(field.value || field.checked);
                    }
                }
            }
            return s.join("&").replace(/%20/g, "+");
        };

        var getAbsolutePathRequest = function(url) {
            var siteUrl = siteRoot.slice(0, -1);
            if (siteUrl && url.indexOf(siteUrl) >= 0 && url.indexOf(partnerUrl) < 0) {
                url = url.replace(siteUrl, partnerUrl);
            } else if (url.indexOf(partnerUrl) < 0) {
                url = partnerUrl + url;
            }
            return url;
    };

    /**
     * Will return the Javascript Object as query string (e.g ?property1=value1&property2=value2)
     * @param {any} objectToConvert a Javascript Object that will follow the format of query string for each property.
     */
        var objectToQueryString = function (objectToConvert, baseQueryString) {
            var keyValuePair = Object.entries(objectToConvert);
            if (keyValuePair && keyValuePair.length > 0) {
                for (var i = 0; i < keyValuePair.length; i++) {
                    if (typeof keyValuePair[i][1] !== "undefined") {
                        baseQueryString += encodeURIComponent(keyValuePair[i][0]) + "=" + encodeURIComponent(keyValuePair[i][1]) + "&";
                    }
                }
            }
            baseQueryString = baseQueryString.substring(0, baseQueryString.length - 1);
            return baseQueryString;
        }

        /**
         * Will return a string based on the object passed. It begins with ? as query string.
         * @param {any} objectToConvert a Javascript Object that will follow the format of query string for each property.
         */
        var convertToQueryString = function(objectToConvert)
        {
            var queryString = "?";
            if (objectToConvert) {
                if (!Object.entries) {
                    //Including the polyfills from NHS.
                    require(["domHelper"], function(domHelper) {
                        return objectToQueryString(objectToConvert, queryString);
                    });
                } else {
                    return objectToQueryString(objectToConvert, queryString);
                }
            }
            return objectToConvert;
        }

        /**
         * @private
         * getAcceptTypes: get the accept types header for Ajax request.
         * 
         * @returns {@String} with the mime types supported.
         */
        function getAcceptTypes() {
            if(browserAcceptTypes) {
                return browserAcceptTypes;
            }

            browserAcceptTypes = localStorage.getItem('browserAcceptTypes');

            return browserAcceptTypes || '*/*';
        }

        return {
            getRequest: getRequest,
            postRequest: postRequest,
            request: request,
            getAbsolutePathRequest: getAbsolutePathRequest,
            convertToQueryString: convertToQueryString
        };
    });
