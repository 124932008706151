define('popularMarketsPills',['fastdom'], (fastdom) => {
  'use strict';

  // Get market pills elements
  const pillsContainer = document.querySelector('[data-pills-container]');
  if (!pillsContainer) {
    return;
  }

  const pillsList = pillsContainer.querySelector('[data-markets-list]');
  const scrollLeftButton = pillsContainer.querySelector('[data-markets-pills-button-left]');
  const scrollRightButton = pillsContainer.querySelector('[data-markets-pills-button-right]');

  // Define margins and scroll limits
  const marginLeftArrow = '16px';
  const hideMarginArrow = '0px';
  let limitRight = 0;

  // Function to set the right limit of the pills container
  const setRightLimit = () => {
    fastdom.measure(() => {
      limitRight = pillsList.getBoundingClientRect().right;
    });
  };

  // Function to update the visibility of the scroll buttons
  const updateChildElementsVisibility = () => {
    fastdom.measure(() => {
      const isListScrolledRight = pillsList.scrollLeft !== 0;
      fastdom.mutate(() => {
        pillsList.style.marginLeft = isListScrolledRight ? marginLeftArrow : hideMarginArrow;
        scrollLeftButton.style.visibility = isListScrolledRight ? 'visible' : 'hidden';

        // Check if the last element is within the right limit
        if (pillsList.lastElementChild.getBoundingClientRect().right > limitRight) {
          scrollRightButton.style.visibility = 'visible';
        } else {
          scrollRightButton.style.visibility = 'hidden';
        }
      });
    });
  };

  // Function to scroll the pills container horizontally
  const scrollHorizontally = (amount) => {
    fastdom.measure(() => {
      const initialScrollLeft = pillsList.scrollLeft;
      const targetScrollLeft = initialScrollLeft + amount;

      fastdom.mutate(() => {
        pillsList.scrollLeft = targetScrollLeft;
        updateChildElementsVisibility();
      });
    });
  };

  // Add event listeners for arrow buttons
  scrollLeftButton.addEventListener('click', () => {
    scrollHorizontally(-300);
  });

  scrollRightButton.addEventListener('click', () => {
    scrollHorizontally(300);
  });

  // Add scroll event to update button visibility
  pillsList.addEventListener('scroll', updateChildElementsVisibility);

  // Add resize event to recalculate limits and update visibility
  window.addEventListener('resize', () => {
    setRightLimit();
    updateChildElementsVisibility();
  });

  // Set the initial right limit and update button visibility on page load
  setRightLimit();
  updateChildElementsVisibility();
});

