//TODO: FIX ISSUE WHEN THERE ARE TOO MANY ITEMS
(function (global, factory) {
  "use strict";
  if ("function" === typeof define && define.amd) {
    // If AMD is supported and the global object (window) in case any JS module is expecting
    // the Carousel to exists on the window object.
    /**
     * @module
     */
    define('Carousel',[], function () {
      return (global.Carousel = factory(global));
    });
  } else if ("object" === typeof exports) {
    // If supports for Common JS is available export the module
    // and pass is value to the global (window) object in case non Common JS code
    // is expected to work with this Carousel Module.
    module.exports = (global.Carousel = factory(global));
  } else {
    // If not support for AMD or Common JS is available register the module in the global scope.
    global.Carousel = factory(global);
  }
})("undefined" !== typeof window ? window : {}, function (root) {

  /**
   * Creates a Carousel instance
   * @constructor
   * @param {HTMLElement} container Carousel container
   * @param {*} options Carousel options
   */
  var Carousel = function (container, options) {
    if (!(container instanceof HTMLElement)) {
      return;
    }
    if (!options || typeof options !== "object") {
      options = {};
    }

    /**
     * Current element index
     * @property {Number} index
     */
    this.index = 0;

    /**
     * Carousel container element
     * @property {HTMLElement} container
     */
    this.container = container;
    container.carousel = this;

    /**
     * @property {HTMLElement} elementsContainer Elements container
     */
    this.elementsContainer = null;

    /**
     * Carousel items
     * @property {HTMLElement[]} items
     */
    this.items = [];

    /**
     * Amount of visible items
     * @property {Number} visibleItems
     */
    this.visibleItems = options.visibleItems;

    /**
     * Amount of visible items when the carousel is in fullscreen mode
     * @property {Number} fullscreenVisibleItems
     */
    this.fullscreenVisibleItems = options.fullscreenVisibleItems;

    /**
     * On change handlers
     * @property {Set.<Function>} onChangeHandlers
     * @private
     */
    this.onChangeHandlers = window.Set ? new Set() : [];

    /**
     * Carousel default step size
     * @property {Number} stepSize
     */
    this.stepSize = options.stepSize || 1;

    /**
     * Carousel full screen step size
     * @property {Number} fullScreenStepSize
     */
    this.fullScreenStepSize = options.fullScreenStepSize;

    /**
     * Carousel controls
     * @property {*} controls
     */
    this.controls = null;

    /**
     * Carousel pager container
     * @property {HTMLElement} pagerContainer
     */
    this.pagerContainer = null;

    /**
     * Touch taken from the user
     * @property {*} _touchTaken
     * @private 
     */
    this._touchTaken = null;

    this.onEnterFullScreenHandlers = window.Set ? new Set() : [];

    this.onExitFullScreenHandlers = window.Set ? new Set() : [];

    /**
     * Before full screen elements height
     * @property {*} _beforeFullScreenElementsHeight
     * @private 
     */
    this._beforeFullScreenElementsHeight = null;

    /**
     * Before full screen polyfill container parent
     * @property {HTMLElement} _beforeFullScreenContainerParent
     * @private
     */
    this._beforeFullScreenContainerParent = null;

    /**
     * Full screen polyfill container
     * @property {HTMLElement} _fullScreenPolyfillContainer
     * @private
     */
    this._fullScreenPolyfillContainer = null;

    this._setUp();

    if (options.controls) {
      this.controls = {};
      this._setUpControls(options.leftArrow, options.rightArrow);
    }


    if (options.pager) {
      this.pager = options.pager;
      if (options.pager.infinitePager) {
        this._setUpInfinitePager();
      } else {
        this._setUpPager();
      }
    }

    var instance = this;
    if (options.clickForFullScreen) {

      this.container.addEventListener("click", function (event) {
        var targetItem = event.target.classList.contains("nhs_carousel_element") ? event.target : event.target.closest(".nhs_carousel_element");
        if (!targetItem) return;
        var index = instance.items.indexOf(targetItem);
        instance.fullScreen();
        if (index !== instance.index) {
          instance.goTo(index);
        }
      });
    }

    this.addOnChangeListener(function () {
      for (var i = instance.index; i <= instance.index + instance.visibleItems + 1; i++) {
        if (instance.items[i]) {
          instance.items[i].classList.add("nhs_carousel_element_visible");
        }
      }
    });
  };

  /**
   * Sets Up the carousel
   * @private
   * @method
   */
  Carousel.prototype._setUp = function () {
    this.updateContentAndLayout();
    this._setUpTouchEvents();
    this._setUpWindowEvents();
  };

  /**
   * Gets the slides width
   * @private
   * @method
   * @returns {Number} Returns the slides width
   */
  Carousel.prototype._getSlidesWidth = function () {
    var sample = this.items[0];
    var width = sample.clientWidth || this.elementsContainer.clientWidth || this.container.clientWidth;
    var marginLeft = parseInt(window.getComputedStyle(sample).marginLeft) || 0;
    var marginRight = parseInt(window.getComputedStyle(sample).marginRight) || 0;
    return width + marginLeft + marginRight;
  };

  /**
   * Updates the carousel content
   * @method
   */
  Carousel.prototype.updateContent = function () {
    var instance = this;
    var carouselElements = this.container.querySelectorAll(".nhs_carousel_element");
    this.items = Array.prototype.slice.call(carouselElements);
    if (!this.elementsContainer) {
      this.elementsContainer = document.createRange().createContextualFragment("<div class='nhs_carousel_elements_container'></div>").querySelector("*");
      this.elementsContainer.style.width = this.container.style.width.indexOf("%") >= 0 ? "100%" : this.container.style.width; //If the width of the container is on percentage, we need to make it a 100% so that the carousel fits the container.
      this.elementsContainer.style.height = this.container.style.height.indexOf("%") >= 0 ? "100%" : this.container.style.height; //If the height of the container is on percentage, we need to make it a 100% so that the carousel fits the container.
      this.container.style.height = 'auto';
      this.items.forEach(function (item) {
        instance.elementsContainer.appendChild(item);
      });
      this.container.insertAdjacentElement("afterbegin", this.elementsContainer);
    }
  };


  Carousel.prototype._getTransform = function (x) {
    return "translate3d(" + x + "px, " + (this.isFullScreen() ? "-50%" : "0px") + ", 0)";
  };

  /**
   * Updates the content layout 
   * @method
   */
  Carousel.prototype.updateContentLayout = function () {
    var sample = this.items[0];
    var _index = this.index;
    this.index = 0;
    var space = 0;
    var fullScreenHeight;
    if (sample) {
      if (!this.isFullScreen()) {
        var visibleAreaWidth = this.elementsContainer.clientWidth || this.container.clientWidth;
        this.visibleItems = !isNaN(this.visibleItems) ? this.visibleItems : Math.round(visibleAreaWidth / this._getSlidesWidth()) || 1;

        if (this._beforeFullScreenElementsHeight === null) {
          this._beforeFullScreenElementsHeight = sample.style.height;
          this._beforeFullScreenElementsLiteralHeight = sample.clientHeight;
          this._beforeFullScreenContainerLiteralHeight = (this.elementsContainer.clientHeight || this.container.clientHeight);
        } else {
          this._beforeFullScreenElementsHeight = this._beforeFullScreenElementsHeight.includes("%") ? sample.style.height : this._beforeFullScreenElementsHeight;
        }

      } else if (this._beforeFullScreenElementsHeight) {
        fullScreenHeight = this._beforeFullScreenElementsHeight.includes("%") ? this._beforeFullScreenElementsHeight : ((this._beforeFullScreenContainerLiteralHeight / this._beforeFullScreenElementsLiteralHeight) * 100) + "%";
      }

      for (var i = 0; i < this.items.length; i++) {
        var item = this.items[i];
        item.setAttribute("data-x", space);
        item.style.transform = this._getTransform(space);
        item.style.width = (100 / (!this.isFullScreen() ? this.visibleItems : this.fullscreenVisibleItems || this.visibleItems)) + "%";
        item.style.height = fullScreenHeight ? fullScreenHeight : this._beforeFullScreenElementsHeight;
        space += this._getSlidesWidth();
      }

      if (!this.isFullScreen()) {
        this._beforeFullScreenElementsLiteralHeight = sample.clientHeight;
        this._beforeFullScreenContainerLiteralHeight = (this.elementsContainer.clientHeight || this.container.clientHeight);
      }
    }
    this.goTo(_index);
  };

  /**
   * Updates the content of the carousel and the layout
   * @method
   */
  Carousel.prototype.updateContentAndLayout = function () {
    this.updateContent();
    this.updateContentLayout();
  };

  /**
   * Sets up all the carousel touch events
   * @private
   * @method
   */
  Carousel.prototype._setUpTouchEvents = function () {
    var instance = this;

    var registerTouch = function (event) {
      if (instance._touchTaken) return;

      var touch = event.touches[0] || event.changedTouches[0];
      instance._touchTaken = {
        id: touch.identifier,
        x: touch.screenX,
        initialX: touch.screenX
      };

      instance.container.classList.add("touched");
      for (var i = 0; i < instance.items.length; i++) {
        var item = instance.items[i];
        item.setAttribute("data-restoreX", item.getAttribute("data-x"));
      }
    };

    this.elementsContainer.addEventListener("touchstart", registerTouch, { passive: true });

    this.elementsContainer.addEventListener("touchmove", function (event) {
      if (!instance._touchTaken) {
        return registerTouch(event);
      }

      var touch = event.touches[0] || event.changedTouches[0];

      if (touch.identifier !== instance._touchTaken.id) return;

      var space = -(instance._touchTaken.x - touch.screenX);
      instance._touchTaken.x = touch.screenX;
      for (var i = 0; i < instance.items.length; i++) {
        var item = instance.items[i];
        var currentX = parseInt(item.getAttribute('data-x'));
        item.setAttribute('data-x', space + currentX);
        item.style.transform = instance._getTransform(item.getAttribute('data-x'));
      }
    }, { passive: true });

    this.elementsContainer.addEventListener("touchend", function (event) {
      var touch = event.touches[0] || event.changedTouches[0];

      if (!instance._touchTaken || touch.identifier !== instance._touchTaken.id)
        return;

      var space = -(instance._touchTaken.initialX - instance._touchTaken.x);
      var width = instance._getSlidesWidth();

      instance.container.classList.remove("touched");
      instance._touchTaken = null;

      var i;
      var item;
      if (
        Math.abs(space) > width * 0.25 &&
        space !== 0 &&
        !(
          (space < 0 && instance.index === instance.items.length - 1) ||
          (space > 0 && !instance.index)
        )
      ) {
        var steps = Math.floor(Math.abs(space) / width);
        var stepSize = (instance.isFullScreen() ? instance.fullScreenStepSize || instance.stepSize : instance.stepSize);
        steps = !steps ? 1 : steps;
        steps = steps < stepSize ? stepSize : steps;
        if (space > 0 && instance.index - steps < 0) {
          steps += instance.index - steps;
        } else if (space < 0 && instance.index + steps > instance.items.length - 1) {
          steps -= instance.index + steps - (instance.items.length - 1);
        }
        instance.index += space < 0 ? steps : -steps;
        if (instance.pager && instance.pager.infinitePager) {
          instance.previousBulletIndex = space < 0 ? instance.index - 1 : instance.index + 1;
        }
        for (i = 0; i < instance.items.length; i++) {
          item = instance.items[i];
          var newX = parseInt(item.getAttribute("data-restoreX")) + (space < 0 ? -steps : steps) * width;
          item.setAttribute("data-x", newX);
          item.style.transform = instance._getTransform(newX);
          item.removeAttribute("data-restore-x");
        }
        instance.onChangeHandlers.forEach(function (fn) {
          fn();
        });
      } else {
        for (i = 0; i < instance.items.length; i++) {
          item = instance.items[i];
          var restoreX = parseInt(item.getAttribute('data-restoreX'));
          item.setAttribute('data-x', restoreX);
          item.style.transform = instance._getTransform(restoreX);
          item.removeAttribute("data-restore-x");
        }
      }

      //check if infinite pagination
      if (instance.pager && instance.pager.infinitePager) {
        instance._updateInfinitePager();
      } else {
        instance._updateControlsAndPager();
      }

    });
  };

  /**
   * Sets up the carousel window events
   * @method
   * @private
   */
  Carousel.prototype._setUpWindowEvents = function () {
    var instance = this;
    if (typeof MutationObserver !== 'undefined') {
      var mutationObserver = new MutationObserver(function () {
        instance.updateContentAndLayout();
        instance.updatePagers();
      });
      mutationObserver.observe(this.elementsContainer,
        {
          childList: true
        });
    }
    window.addEventListener("resize", function () {
      instance.updateContentLayout();
    });
    window.addEventListener("orientationchange", function () {
      instance.updateContentLayout();
    });
    window.addEventListener("keydown", function (event) {
      switch (event.key) {
        case "ArrowRight":
          instance.goNext();
          break;
        case "ArrowLeft":
          instance.goBack();
      }
    });
    document.addEventListener("fullscreenchange", function () {
      if (!instance.isFullScreen()) return;
      if (document.fullscreenElement !== instance.container) {
        instance.container.classList.remove("fullscreen");
      }
      instance.updateContentLayout();
    });
  };

  /**
   * Creates an li HTML to render the bullets on the pager.
   * @method
   */
  Carousel.prototype.createInfinitePagerElements = function () {
    if (!this.pagerContainer) return;
    var pagerElement; //Will contain every pager bullet created.
    var infinitePagerDocumentFragment = document.createDocumentFragment();
    this.pagerContainer.innerHTML = "";
    for (var i = 0; i < this.items.length; i++) {
      pagerElement = document.createElement("li");
      pagerElement.setAttribute("data-index", i);
      pagerElement.classList.add("nhs_carousel_pager_item");
      pagerElement.classList.add("infinite_item");
      infinitePagerDocumentFragment.appendChild(pagerElement);
    }
    if (infinitePagerDocumentFragment.children && infinitePagerDocumentFragment.children.length > 0 && infinitePagerDocumentFragment.children.length >= this.index) { //Only if there are bullets and if the index exists in those bullets.
      infinitePagerDocumentFragment.children[this.index].classList.add("active"); //Adding the current index bullet to be highlighted.
    }
    this.pagerContainer.appendChild(infinitePagerDocumentFragment);
  };

  /**
   * Updates the pagers
   * @method
   */
  Carousel.prototype.updatePagers = function () {
    if (!this.pagerContainer) return;
    this.pagerContainer.innerHTML = "";
    for (var i = 0; i < this.items.length; i++) {
      this.pagerContainer.appendChild(document.createRange().createContextualFragment("<li class='nhs_carousel_pager_item " + (i === this.index ? "active" : "") + "' data-index='" + i + "'></li>"));
    }
  };

  /**
   * Sets up the pager
   * @method
   */
  Carousel.prototype._setUpPager = function () {
    var instance = this;
    this.pagerContainer = document.createRange().createContextualFragment("<ul class='nhs_carousel_pager_container'></ul>").querySelector("*");
    this.updatePagers();
    this.pagerContainer.addEventListener("click", function (event) {
      var pagerItem = event.target;
      if (!pagerItem.getAttribute('data-index')) return;
      instance.goTo(parseInt(pagerItem.getAttribute('data-index')));
    });

    this.container.appendChild(this.pagerContainer);
  };

  /**
   * The infinite pager will show always 3 bullets and as soon as the user moves around the carousel, we will keep the point on the center, showing 5 bullets and if they reach the end
   * or the beginning, then we move the bullet either to the right or to the left.
   * @method
   */
  Carousel.prototype._setUpInfinitePager = function () {
    var instance = this;
    instance.pagerContainer = document.createElement("ul");
    instance.pagerContainer.classList.add("nhs_carousel_pager_container");
    instance.pagerContainer.classList.add("infinite");
    instance.createInfinitePagerElements();
    instance.pagerContainer.addEventListener("click", function (event) {
      var pagerItem = event.target;
      if (!pagerItem.getAttribute('data-index')) return;
      instance.goTo(parseInt(pagerItem.getAttribute('data-index')));
    });
    instance.container.appendChild(instance.pagerContainer);
    instance.pager.percentageToMoveOnXVector = instance.pager.percentageToMoveOnXVector ? instance.pager.percentageToMoveOnXVector : 185;
  };

  /**
   * Updates the constrols visibility
   * @private
   * @method
   */
  Carousel.prototype._updateControlsVisibility = function () {
    var instance = this;
    if (!instance.controls) {
      return;
    }
    if (instance.index === 0) {
      instance.controls.leftArrow.style.display = "none";
    } else {
      instance.controls.leftArrow.style.display = "block";
    }

    if (instance.index === instance.items.length - 1) {
      instance.controls.rightArrow.style.display = "none";
    } else {
      instance.controls.rightArrow.style.display = "block";
    }
  };

  /**
   * Updates the active pager
   * @private
   * @method
   */
  Carousel.prototype._updateActivePager = function () {
    if (!this.pagerContainer) return;

    var active = this.pagerContainer.querySelector(".nhs_carousel_pager_item.active");
    if (active) {
      active.classList.remove("active");
    }

    active = this.pagerContainer.querySelector(".nhs_carousel_pager_item[data-index='" + this.index + "']");
    if (active) {
      active.classList.add("active");
    }
  };

  /**Whenever we swipe, we need to update and move the classes to the next elements in pager and the translate3d property of each.*/
  Carousel.prototype._updateInfinitePager = function () {
    if (!this.pagerContainer) return;
    var instance = this;
    var pagerElements = instance.pagerContainer.querySelectorAll(".infinite_item");

    //Remove current bullet class of active bullet.
    var previousSelectedElement = instance.pagerContainer.querySelector(".nhs_carousel_pager_item.active");
    if (previousSelectedElement) {
      previousSelectedElement.classList.remove("active");
    }

    //Moving the bullet class of active bullet to the current selected index.
    var active = this.pagerContainer.querySelector(".nhs_carousel_pager_item[data-index='" + this.index + "']");
    if (active) {
      active.classList.add("active");
    }

    var transformXMoveValue = instance.pager.percentageToMoveOnXVector * (instance.index - 1); //Set the value that we need to move the bullets. 
    transformXMoveValue = instance.previousIndex > instance.index ? transformXMoveValue : transformXMoveValue * -1; //It must be either positive or negative value depending on we swiping left or right.
    if (this.index > 0 && (this.previousBulletIndex !== 0) && (instance.items.length - 1 !== this.index) && pagerElements.length > 0) {//Check if we are hitting the end or we are at the beggining, we don't need to move the bullets.
      for (var counter = 0; counter < pagerElements.length; counter++) {
        pagerElements[counter].style.transform = "translate3d(" + transformXMoveValue + "%, 0, 0)";
      }
    }

    this._updateControlsVisibility();
  }

  /**
   * Updates the controls visibility and the pagers
   * @private
   * @method
   */
  Carousel.prototype._updateControlsAndPager = function () {
    this._updateControlsVisibility();
    if (this.pager && this.pager.infinitePager) {
      this._updateInfinitePager();
    } else {
      this._updateActivePager();
    }

  };

  /**
   * Sets up carousel controls
   * @private
   * @method
   * @param {HTMLElement} leftArrow Left arrow element
   * @param {HTMLElement} rightArrow Right arrow element
   */
  Carousel.prototype._setUpControls = function (leftArrow, rightArrow) {
    var instance = this;
    if (leftArrow) {
      this.controls.leftArrow = leftArrow;
    } else {
      this.controls.leftArrow = document.createRange().createContextualFragment("<div class='nhs_carousel_control left' data-event='true' data-ga-event-category='Home owers values' data-ga-event-action='Arrow Click' data-ga-event-label='Left' data-ga-event-no-interaction='1'></div>").querySelector("*");
      this.container.appendChild(this.controls.leftArrow);
    }

    if (rightArrow) {
      this.controls.rightArrow = rightArrow;
    } else {
      this.controls.rightArrow = document.createRange().createContextualFragment("<div class='nhs_carousel_control right' data-event='true' data-ga-event-category='Home owers values' data-ga-event-action='Arrow Click' data-ga-event-label='Right' data-ga-event-no-interaction='1'></div>").querySelector("*");
      this.container.appendChild(this.controls.rightArrow);
    }

    this.controls.leftArrow.addEventListener("click", function () {
      instance.goBack();
    });

    this.controls.rightArrow.addEventListener("click", function () {
      instance.goNext();
    });

    instance._updateControlsVisibility();
  };

  /**
   * Lets change the carrouse from the current item
   * @method
   * @param {Number} spaces Spaces to move
   */
  Carousel.prototype.go = function (spaces) {
    var instance = this;
    if (isNaN(spaces)) {
      return;
    }
    var steps = Math.abs(spaces);
    if (spaces < 0 && instance.index - steps < 0) {
      steps += instance.index - steps;
    } else if (spaces > 0 && instance.index + steps > instance.items.length - 1) {
      steps -= (instance.index + steps) - (instance.items.length - 1);
    }
    instance.index += spaces > 0 ? steps : -steps;

    var width = this._getSlidesWidth();
    var space = ((spaces > 0 ? -1 : 1) * steps) * width;

    for (var i = 0; i < this.items.length; i++) {
      var item = this.items[i];
      var currentX = parseInt(item.getAttribute('data-x'));
      item.setAttribute('data-x', space + currentX);
      item.style.transform = instance._getTransform(item.getAttribute('data-x'));
    }

    instance._updateControlsAndPager();

    this.onChangeHandlers.forEach(function (fn) {
      fn();
    });
  };

  /**
   * Goes to an specific carousel item
   * @method
   * @param {Number} index Slide index
   */
  Carousel.prototype.goTo = function (index) {
    if (index < this.index && index > -1) {
      this.goBack(this.index - index);
    } else if (index > this.index && index < this.items.length) {
      this.goNext(index - this.index);
    }
  };

  /**
   * Changes the carousel to the next slide
   * @method
   * @param {Number} spaces Spaces to move
   */
  Carousel.prototype.goNext = function (spaces) {
    this.go(!isNaN(spaces) ? spaces : (this.isFullScreen() ? this.fullScreenStepSize || this.stepSize : this.stepSize));
  };

  /**
   * Changes the carousel to the previuos slide
   * @method
   * @param {Number} spaces Spaces to move
   */
  Carousel.prototype.goBack = function (spaces) {
    this.go(-(!isNaN(spaces) ? spaces : (this.isFullScreen() ? this.fullScreenStepSize || this.stepSize : this.stepSize)));
  };

  /**
   * Adds a function to run after the carousel changes
   * @method
   * @param  {Function} fn Function
   */
  Carousel.prototype.addOnChangeListener = function (fn) {
    if (typeof fn !== "function") return;
    if (typeof this.onChangeHandlers.add === 'function') {
      this.onChangeHandlers.add(fn);
    } else {
      this.onChangeHandlers.push(fn);
    }
  };

  /**
   * Removes a function added to run after the carousel changes
   * @method
   * @param {Function} fn Function
   */
  Carousel.prototype.removeOnChangeListener = function (fn) {
    if (typeof this.onChangeHandlers.delete === 'function') {
      this.onChangeHandlers.delete(fn);
    } else {
      this.onChangeHandlers.pop(fn);
    }
  };

  /**
   * Sets the carousel in full screen mode
   * @method
   */
  Carousel.prototype.fullScreen = function () {
    var instance = this;
    if (instance.isFullScreen()) {
      return;
    }
    if (!instance.exitFullScreenBtn) {
      instance.exitFullScreenBtn = document.createRange().createContextualFragment("<span class='nhs_carousel_exit_fullscreen'></span>").querySelector("*");
      instance.exitFullScreenBtn.addEventListener("click", function () {
        instance.exitFullScreen();
      });
    }
    instance.container.appendChild(instance.exitFullScreenBtn);

    instance.container.classList.add("fullscreen");
    if (instance.container.webkitRequestFullscreen) {
      instance.container.webkitRequestFullscreen();
    } else if (instance.container.mozRequestFullscreen) {
      instance.container.mozRequestFullscreen();
    } else {
      if (!instance._fullScreenPolyfillContainer) {
        document.body.classList.add("nhs_NoScrollComplete");
        instance.container.style.height = "100%";
        instance._fullScreenPolyfillContainer = document.createRange().createContextualFragment("<div class='nhs_carousel_fullscreen_polyfill_container'></div>").querySelector("*");
        document.body.appendChild(instance._fullScreenPolyfillContainer);
      }
      instance._fullScreenPolyfillContainer.style.display = "block";
      instance._beforeFullScreenContainerParent = instance.container.parentElement;
      instance._fullScreenPolyfillContainer.appendChild(instance.container);
    }

    instance.updateContentLayout();
    setTimeout(function () {
      instance.updateContentLayout();
    }, 100);
    instance.onEnterFullScreenHandlers.forEach(function (handler) {
      handler();
    });
  };

  /**
   * Exits full screen mode
   * @method
   */
  Carousel.prototype.exitFullScreen = function () {
    var instance = this;
    instance.container.classList.remove("fullscreen");
    if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.mozExitFullscreen) {
      document.mozExitFullscreen();
    } else {
      instance._fullScreenPolyfillContainer.style.display = "none";
      document.body.classList.remove("nhs_NoScrollComplete");
      instance._beforeFullScreenContainerParent.appendChild(instance.container);
    }
    if (instance.exitFullScreenBtn && instance.exitFullScreenBtn.parentElement) {
      instance.exitFullScreenBtn.parentElement.removeChild(instance.exitFullScreenBtn);
    }

    setTimeout(function () {
      instance.updateContentLayout();
    }, 100);
    instance.onExitFullScreenHandlers.forEach(function (handler) {
      handler();
    });
  };

  /**
   * Checks if the carousel is in full screen mode
   * @method
   */
  Carousel.prototype.isFullScreen = function () {
    return document.fullscreenElement === this.container || this.container.classList.contains("fullscreen");
  };

  /**
   * Adds a function to run after the carousel enters full screen mode
   * @method
   * @param  {Function} fn Function
   */
  Carousel.prototype.addOnEnterFullScreen = function (fn) {
    if (typeof this.onEnterFullScreenHandlers.add === 'function') {
      this.onEnterFullScreenHandlers.add(fn);
    } else {
      this.onEnterFullScreenHandlers.push(fn);
    }
  };

  /**
   * Removes a function added to run after the carousel enters full screen mode
   * @method
   * @param  {Function} fn Function
   */
  Carousel.prototype.removeOnEnterFullScreen = function (fn) {
    if (typeof this.onEnterFullScreenHandlers.remove === 'function') {
      this.onEnterFullScreenHandlers.remove(fn);
    } else {
      this.onEnterFullScreenHandlers.pop(fn);
    }
  };

  /**
   * Adds a function to run after the carousel exits full screen mode
   * @method
   * @param  {Function} fn Function
   */
  Carousel.prototype.addOnExitFullScreen = function (fn) {
    if (typeof this.onExitFullScreenHandlers.add === 'function') {
      this.onExitFullScreenHandlers.add(fn);
    } else {
      this.onExitFullScreenHandlers.push(fn);
    }
  };

  /**
   * Removes a function added to run after the carousel exits full screen mode
   * @method
   * @param  {Function} fn Function
   */
  Carousel.prototype.removeOnExitFullScreen = function (fn) {
    if (typeof this.onExitFullScreenHandlers.remove === 'function') {
      this.onExitFullScreenHandlers.remove(fn);
    } else {
      this.onExitFullScreenHandlers.pop(fn);
    }
  };

  return Carousel;
});
/*** Made with ❤ by NewHomeSource team ***/;
