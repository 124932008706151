/*
 * summary: this module is to load a modal on the initial load
 * hidden input data:
 *      data-auto-open-modal-query-string="queryString from url"
 * button with the modal functionality:
 *      data-auto-open-modal-(name of the modal, example: request-appointment)
 */
(function initialLoadModalHandler(global) {
  'use strict';

  global.NewHomeSource = global.NewHomeSource || {};
  const previousInitialLoadModalHandler = global.NewHomeSource.InitialLoadModalHandler;
  const queySelectorHiddenInput = '[data-auto-open-modal-query-string]';
  const configHiddenInput = document.querySelector(queySelectorHiddenInput);
  const queryString = configHiddenInput.dataset.autoOpenModalQueryString;

  if (!configHiddenInput || !queryString) {
    return null;
  }

  /**
   * @private
   * removeQueryStringFromUrl: function to remove the queryString from the url
   */
  function removeQueryStringFromUrl() {
    const currentUrl = global.location.href;

    const url = new URL(currentUrl);
    const params = new URLSearchParams(url.search);
    params.delete('autoopenmodal');
    url.search = params.toString();
    const newUrl = url.toString();

    global.history.pushState({}, null, newUrl);
  }

  /**
   * @constructs NewHomeSource.InitialLoadModalHandler
   */
  function InitialLoadModalHandler() {
  }

  /**
   * @private
   * DisplayModal: function to display the modal from the url in the target element
   * @param {string} querySelectorForData query selector to get the needed data
   */
  InitialLoadModalHandler.DisplayModal = function DisplayModal() {
    const querySelectorModal = `[data-auto-open-modal-${queryString}]`;
    const targetElement = document.querySelector(querySelectorModal);

    if (targetElement) {
      removeQueryStringFromUrl();
      targetElement.click();
    }
  };

  /**
   * @public
   *
   * noConflict: Returns control of NewHomeSource.InitialLoadModalHandler to the previous script.
   */
  InitialLoadModalHandler.noConflict = function noConflict() {
    global.NewHomeSource.InitialLoadModalHandler = previousInitialLoadModalHandler;
    return InitialLoadModalHandler;
  };

  global.NewHomeSource.InitialLoadModalHandler = InitialLoadModalHandler;

  return InitialLoadModalHandler;
}(window));

define("initialLoadModalHandler", function(){});

