define('htmlSectionLazyLoadConfig',[], function() {
    var _configurationInstance = {
        NoPhotoUrl: "https://beta-nhs-static-secure.akamaized.net/GlobalResources14/Default/images/no_photo/no_photos_180x120.png",
        PendingLoadSectionSelector: "[data-lazy-section]:not([data-lazy-loaded-section])",
        LazyLoadedAttributeName: "data-lazy-loaded-section",
        DefaultIntersectionRootMargin: "300px 0px",
        DefaultIntersectionThreshold: 0.3
    };

    var getConfigurationInstance = function() {
        if (Object.assign) { //Don't want to break in case somehow this gets called by unsupported browsers like IE. getConfigValue can be used for that.
            return Object.assign({}, _configurationInstance);
        }
        return {};
    }

    var getConfigValue = function(configKey) {
        return _configurationInstance[configKey];
    }

    return {
        getConfigurationInstance: getConfigurationInstance,
        getConfigValue: getConfigValue

    }
   
});
