/*
Module that relies on the Intersection Observer to work. It will grab all the images that have the data-lazy-load attribute and
once the Intersection detects them, they will swap the data-src with the src (there are other scenarios as well). Please notice
that there's a related component from C#, which is ImageViewModel
*/
define('imageLazyLoad',["imageLazyLoadConfig"], function (imageLazyLoadConfig) {
  (function () {
    "use strict";
    var intersectionObserverInstance;
    /**
    * Adds no photo since when trying to load the image, there was an error.
    * @private
    * @returns {undefined}
    */
    var onErrorImageEvent = function () {
      this.setAttribute(imageLazyLoadConfig.LazyLoadedAttributeName, "true");
      this.classList.remove(imageLazyLoadConfig.getConfigurationInstance().LazyLoadCssClass, imageLazyLoadConfig.getConfigurationInstance().LoadSizeCssClass);
      this.src = imageLazyLoadConfig.getConfigurationInstance().NoPhotoUrl;
      if (this.srcset) {
        this.srcset = imageLazyLoadConfig.getConfigurationInstance().NoPhotoUrl;
      }
    };


    /**
    * Add the attribute of lazyloaded to the image.
    * @private
    * @returns {undefined}
    */
    var onLoadImageEvent = function () {
      this.setAttribute(imageLazyLoadConfig.getConfigurationInstance().LazyLoadedAttributeName, "true");
      this.classList.remove(imageLazyLoadConfig.getConfigurationInstance().LazyLoadCssClass, imageLazyLoadConfig.getConfigurationInstance().LoadSizeCssClass);
    }

    /**
    * Logic where the src is defined based on the data source elements of the image,
    * where the full image is placed.
    * @private
    * @returns {undefined}
    */
    function loadDataSourceSetImage(lazyImage) {
      if (lazyImage.target) {
        lazyImage = lazyImage.target;
      }

      if (!lazyImage) {
        lazyImage = this;
      }

      var srcsetImage = lazyImage.dataset.srcset;

      lazyImage.onload = onLoadImageEvent;
      lazyImage.onerror = onErrorImageEvent;

      if (srcsetImage !== "" && srcsetImage) {
        var srcsetValues = srcsetImage.split(" ");
        if (srcsetValues[0] && srcsetValues[0].indexOf("http") >= 0) {
          lazyImage.src = srcsetValues[0];
          lazyImage.srcset = srcsetImage;
        }
      } else if (lazyImage.dataset.src) {
        lazyImage.src = lazyImage.dataset.src;
      }
    }

		/**
		* Creates the Intersection Observer and also defines the images it will look at. If there are
		* not any that has the data-lazy-load attribute, will not run nor if intersection is not defined
		* @private
		* @returns {undefined}
		*/
    var setupIntersectionObserver = function () {
      var lazyImages = [].slice.call(document.querySelectorAll(imageLazyLoadConfig.getConfigurationInstance().ImageNotLoadedSelector));
      if (lazyImages.length > 0 && window.IntersectionObserver) {
        var config = {
          root: null,
          rootMargin: imageLazyLoadConfig.getConfigurationInstance().DefaultIntersectionRootMargin,
          threshold: imageLazyLoadConfig.getConfigurationInstance().DefaultIntersectionThreshold
        };
        intersectionObserverInstance = new IntersectionObserver(function (entries) {
          var entry;
          for (var entryCounter = 0; entryCounter < entries.length; entryCounter++) {
            entry = entries[entryCounter];
            if (entry.intersectionRatio > 0 && entry.isIntersecting) {
              var lazyImage = entry.target;
              loadDataSourceSetImage(lazyImage);
              intersectionObserverInstance.unobserve(lazyImage);
            }
          }
        }, config);
        var lowQualityImg;
        for (var lazyImageCounter = 0; lazyImageCounter < lazyImages.length; lazyImageCounter++) {
          //Adding to observer
          intersectionObserverInstance.observe(lazyImages[lazyImageCounter]);
          //Eager Load based on the low quality image.
          lowQualityImg = lazyImages[lazyImageCounter].getAttribute(imageLazyLoadConfig.getConfigurationInstance().LowQualityAttribute);
          if (lowQualityImg) {
            lazyImages[lazyImageCounter].removeAttribute(imageLazyLoadConfig.getConfigurationInstance().LowQualityAttribute);
            lazyImages[lazyImageCounter].src = lowQualityImg;
          }
        }
      }
      else {
        lazyImages = document.querySelectorAll(".nhs_lazyLoadImage");
        for (var i = 0; i < lazyImages.length; i++) {
          var image = lazyImages[i];
          image.src = image.dataset.src;
        }
      }
    }

    /**
    * Self registering method to run the script.
    * @public
    * @returns {undefined}
    */
    var startLoadImagesHandler = function () {
      setupIntersectionObserver();
    }

    startLoadImagesHandler();
  })();
});
