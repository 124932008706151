/*
Module
*/
define('htmlSectionLazyLoad',["ajaxHelper", "htmlSectionLazyLoadConfig"], function(ajaxHelper, htmlSectionLazyLoadConfig) {
    (function() {
        "use strict";
        var intersectionObserverInstance;

       /**
       * Logic where the src is defined based on the data source elements of the image,
       * where the full image is placed.
       * @private
       * @returns {undefined}
       */
        function loadSection(lazySection) {
            if (lazySection.target) {
                lazySection = lazySection.target;
            }

            if (!lazySection) {
                lazySection = this;
            }

            var url = location.protocol + "//" + location.hostname + lazySection.getAttribute("data-url");
            ajaxHelper.getRequest(url, null, null,
                {
                    async: true,
                    success: function (response) {
                        var responseFragment = document.createRange().createContextualFragment(response);
                        lazySection.appendChild(responseFragment);
                    }
            });
        }

       /**
       * Creates the Intersection Observer and also defines the images it will look at. If there are
       * not any that has the data-lazy-load attribute, will not run nor if intersection is not defined
       * @private
       * @returns {undefined}
       */
       var setupIntersectionObserver = function () {
            var lazySections = [].slice.call(document.querySelectorAll(htmlSectionLazyLoadConfig.getConfigurationInstance().PendingLoadSectionSelector));
            if (lazySections.length > 0 && window.IntersectionObserver) {
                var config = {
                    rootMargin: htmlSectionLazyLoadConfig.getConfigurationInstance().DefaultIntersectionRootMargin,
                    threshold: htmlSectionLazyLoadConfig.getConfigurationInstance().DefaultIntersectionThreshold
                };
                intersectionObserverInstance = new IntersectionObserver(function (entries) {
                    var entry;
                    for (var entryCounter = 0; entryCounter < entries.length; entryCounter++) {
                        entry = entries[entryCounter];
                        if (entry.intersectionRatio > 0 && entry.isIntersecting) {
                            var lazySection = entry.target;
                            loadSection(lazySection);
                            intersectionObserverInstance.unobserve(lazySection);
                        }
                    }
                }, config);
                
                for (var lazyImageCounter = 0; lazyImageCounter < lazySections.length; lazyImageCounter++) {
                    //Adding to observer
                    intersectionObserverInstance.observe(lazySections[lazyImageCounter]);
                }
            }
       }

       /**
       * Self registering method to run the script.
       * @public
       * @returns {undefined}
       */
       var startLoadSectionHandler = function() {
           setupIntersectionObserver();
       }

       startLoadSectionHandler();
    })();
});
