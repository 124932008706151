require(["ajaxHelper"], function(ajaxHelper) {
    ajaxHelper.getRequest("/homepage/getlocationbasedinformation", null, null, {
        async: true,
        success: function (response) {
            response = JSON.parse(response);

            var sectionTitle = document.querySelector("[data-statistics-title]");
            var homesCount = document.querySelector("[data-location-homes-count]");
            var qmiCount = document.querySelector("[data-location-qmi-count]");
            var commsCount = document.querySelector("[data-location-comms-count]");

            homesCount.children[0].textContent = response.HomesCount;
            qmiCount.children[0].textContent = response.QmiCount;
            commsCount.children[0].textContent = response.CommsCount;
            sectionTitle.textContent = response.TitleText;
            sectionTitle.style.display = "block";

            if (!response.IsNationWide) {
                qmiCount.href = response.QmiUrl;
                homesCount.href = response.HomesUrl;
                commsCount.href = response.CommunitiesUrl;
            }
        }
    });
});
define("locationCountsGetter", function(){});

