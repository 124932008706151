define('domHelper',[], function () {
  var getPassiveOptions = function (passive) {
        var opts = new Object();
        opts.passive = !!passive;
        return opts;
    };

    /**
     * 
     * Attaches a event to the DOM Element.
     * 
     * @private 
     * @param {string} event name that will be attached to the Element.
     * @param {function} callback JavaScript function to execute when the event is triggered.
     */
    function attachEvent(eventName, callback, passive) {
        var opts = getPassiveOptions(passive);

        if (document.addEventListener) {
            this.addEventListener(eventName, callback, opts);
        } else if (document.attachEvent) {
            this.attachEvent(eventName, callback, opts);
        }
    };

    /**
     * 
     * Attaches a event to the DOM Element.
     * 
     * @private 
     * @param {string} event name that will be attached to the Element.
     * @param {function} callback JavaScript function to execute when the event is triggered.
     */
    function detachEvent(eventName, callback, passive) {
        var opts = getPassiveOptions(passive);

        if (document.removeEventListener) {
            this.removeEventListener(eventName, callback, opts);
        } else if (document.detachEvent) {
            this.detachEvent(eventName, callback, opts);
        }
    };

    Element.prototype.removeEventFromElement = detachEvent;
    Element.prototype.addEventToElement = attachEvent;

    Document.prototype.addEventToElement = attachEvent;
    Document.prototype.removeEventFromElement = detachEvent;

    /**
     * 
     * Checks if an Element has a Parent with the given ID.
     * 
     * @private 
     * @param {string} id that will be searched in the parent nodes.
     */
    Element.prototype.hasParent = function (id) {
        if (this.id === id) {
            return true;
        }

        return this.parentNode && this.parentNode !== document.body && this.parentNode.hasParent(id);
    }

    /**
     * 
     * Checks if an Element has a class associated.
     * 
     * @private 
     * @param {string} className that will be searched in the element.
     */
    Element.prototype.hasClass = function (className) {
        if (!this.classList) {
            return false;
        }

        return this.classList.contains(className);
    }

    if ((typeof window.CustomEvent === "function")) {
        var onClassListChange = new Event("onClassListChange",
            {
                bubbles: true
            });


        Element.prototype.addClass = function (className) {
            if (this.hasClass(className)) {
                return true;
            }
            this.classList.add(className);
            this.dispatchEvent(onClassListChange);
            return this;
        }
    }

    Element.prototype.removeClass = function (className) {
        if (!this.hasClass(className)) {
            return true;
        }

        this.classList.remove(className);
        this.dispatchEvent(onClassListChange);
        return this;
    }

    Element.prototype.insertAfter = function (nodeToInsert) {
        this.parentNode.insertBefore(nodeToInsert, this.nextSibling);
    }
    /**
     * 
     * Hides and element from the DOM.
     * 
     * @private 
     */
    Element.prototype.hide = function () {
        this.style.display = "none";
        return this;
    }

    /**
     * 
     * Displays and element from the DOM.
     * 
     * @private 
     */
    Element.prototype.show = function () {
        this.style.display = "block";
        return this;
    }

    /**
     * 
     * Checks if an Element is visible.
     * 
     * @private 
     */
    Element.prototype.isVisible = function () {
        var style = window.getComputedStyle(this);

        return ((style.display !== "none") && (style.visibility !== "hidden")) ? true : false;
    };

    // Start of ticket BDR-17
    // NodeList.forEach Pollyfill
    if (!NodeList.prototype.forEach || !DOMTokenList.prototype.forEach) {
        var propertyName = "forEach";
        var forEach = function (callback) {
            if (typeof callback !== "function") {
                throw new TypeError(typeof callback + " is not a function");
            }
            var enumerable = this;
            var index = 0;
            for (index; index < enumerable.length; index++) {
                if (callback(enumerable[index], index, enumerable) === false) {
                    break;
                }
            }
        };
        if (!NodeList.prototype.forEach) {
            Object.defineProperty(NodeList.prototype, propertyName, {
                enumerable: false,
                configurable: false,
                writable: false,
                value: forEach
            });
        }
        if (!DOMTokenList.prototype.forEach) {
            Object.defineProperty(DOMTokenList.prototype, propertyName, {
                enumerable: false,
                configurable: false,
                writable: false,
                value: forEach
            });
        }
    }

    if (!Node.prototype.replaceWith || !Element.prototype.replaceWith) {
        var replaceWith = function () {
            var currentElement = this;
            var parent = currentElement.parentNode;
            if (!parent) {
                return false;
            }
            //Remove myself if parameters are empty
            var length = arguments.length;
            if (!length) {
                return parent.removeChild(currentElement);
            }
            var index = 0;
            var currentNode;
            for (index; index < length; index++) {
                currentNode = arguments[index];
                if (typeof currentNode !== 'object') {
                    currentNode = document.createTextNode(currentNode);
                } else if (currentNode.parentNode) {
                    currentNode.parentNode.removeChild(currentNode);
                }
                if (index === length - 1) {
                    parent.replaceChild(currentNode, currentElement);
                } else {
                    parent.insertBefore(currentNode, currentElement);
                }
            }
        };
        if (!Element.prototype.replaceWith) {
            Object.defineProperty(Element.prototype, 'replaceWith', {
                enumerable: false,
                configurable: false,
                writable: false,
                value: replaceWith
            });
        }
        if (!Node.prototype.replaceWith) {
            Object.defineProperty(Node.prototype, 'replaceWith', {
                enumerable: false,
                configurable: false,
                writable: false,
                value: replaceWith
            });
        }
    }
    //End of ticket BDR-17    
});
