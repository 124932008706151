require(['SearchFormComponent', 'Carousel', 'imageLazyLoad', 'htmlSectionLazyLoad', 'locationCountsGetter', 'initialLoadModalHandler', 'popularMarketsPills'],
  function (SearchFormComponent, Carousel) {
    let form = document.querySelector('#searchFormHeroImage');

    var search = new SearchFormComponent({
      form: document.getElementById("searchFormHeroImage"),
      validationError: document.querySelector("#heroSearchboxErrorMessage"),
      submitButton: document.querySelector("[data-search-submit]"),
      searchTextField: form.querySelector("[data-component-rol='target-input']"),
      shouldAlwaysSubmitForm: true,
      useAutocompleteForSubmit: true
    });

    var testimonialCarousel = new Carousel(document.getElementById("testimonialContainer"), {
      stepSize: 1,
      controls: true,
      visibleItems: 1,
      rightArrow: document.querySelector('[data-rigth]'),
      leftArrow: document.querySelector('[data-left]')
    });

    if (window.NewHomeSource.InitialLoadModalHandler) {
      window.NewHomeSource.InitialLoadModalHandler.DisplayModal();
    }
  });
define("nhs/views/home/Show", function(){});

